import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardEditContainer from './BoardEditContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage7 = (): ReactElement => {
  return (
    <>
      <Route exact path="/board-7" component={BoardListContainer}></Route>
      <Route exact path="/board-7/detail/:id" component={BoardDetailContainer}></Route>
      <Route exact path="/board-7/regist" component={BoardRegistContainer}></Route>
      <Route exact path="/board-7/edit/:id" component={BoardEditContainer}></Route>
    </>
  );
};

export default BoardPage7;
