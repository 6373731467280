import './SideNavSection.scss';

import { ReactElement } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { nickname } from '../nickname';

const SideNavSection = (): ReactElement => {
  const nickName = nickname;

  return (
    <div id="SideNavSection">
      <Nav className="flex-column">
        <NavLink to="/board" className="nav-link">
          나도한마디
        </NavLink>
        {nickName.map((value, index) => {
          return (
            <NavLink to={'/board-' + (index + 1)} className="nav-link" key={value}>
              {nickName[index]}의 나도한마디
            </NavLink>
          );
        })}
        <NavLink key="event" to="/event" className="nav-link">
          경조사
        </NavLink>
      </Nav>
    </div>
  );
};

export default SideNavSection;
