import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import BoardService from '../../../service/board5/BoardService';
import { UpdateBoardRequest } from '../../../service/board5/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode, SelectItem } from '../../../service/common/model/CommonCode';
import ConfirmModal from '../../common/ConfirmModal';

const BoardEditForm = (): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const boardId = Number(id);

  const [boardTitle, setBoardTitle] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [categoryList, setCategoryList] = useState<SelectItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const commonService = new CommonCodeService();
  const boardService = new BoardService();

  /* eslint-disable */
  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    getCategoryList().then((res) => {
      if (res && res.length > 0) {
        const categoryListTmp = res.map((commonCode: CommonCode) => {
          return { value: commonCode.codeId, label: commonCode.codeName };
        });
        setCategoryList(categoryListTmp);
      }
    });

    void boardService.getBoardDetail(boardId).then((response) => {
      if (response.successOrNot === 'Y') {
        /* response data를 받아서 각각의 값을 boardTitle, selectedCategory, contents에 set해주는 코드 작성 */
        const boardData = response.data;

        setBoardTitle(boardData.boardTitle);
        setSelectedCategory(boardData.category);
        setContents(boardData.boardContents);
      }
    });
  }, []);
  /* eslint-enable */

  const handleTitleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.trimLeft().length > event.target.maxLength) {
      event.target.value = event.target.value.trimLeft().slice(0, event.target.maxLength);
    }
    setBoardTitle(event.target.value.trimLeft());
  };

  const handleCategoryChanged = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeContent = (event) => {
    if (event.target.value.trimLeft().length > event.target.maxLength) {
      event.target.value = event.target.value.trimLeft().slice(0, event.target.maxLength);
    }
    setContents(event.target.value.trimLeft());
  };

  const handleCancelBtn = () => {
    /* 게시글 상세 화면으로 이동하는 코드작성 */
    history.push(`/board-5/detail/${boardId}`);
  };

  const handleSaveBtn = () => {
    const requestData: UpdateBoardRequest = {
      /* 객체의 값에 boardId, boardTitle, selectedCategory, contents 값을 할당해주는 코드 작성*/
      boardId: boardId,
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };

    /* boardService의 updateBoardData 함수를 호출하여 requestData를 넘겨주고, 성공일시 게시글 목록으로 화면이동하는 코드작성*/
    void boardService.updateBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-5');
      }
    });
  };

  return (
    <div id="BoardEditForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">수정하기</span>
      </div>
      <Form className="inputForm">
        <Form.Row className="category-row">
          <Row>
            <Col className="title-col">
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="제목을 입력하세요."
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={handleTitleChanged}
                value={boardTitle}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select
                defaultValue=""
                className="category"
                value={selectedCategory}
                onChange={handleCategoryChanged}
                placeholder={'선택하세요'}
              >
                {categoryList.map((category) => {
                  return (
                    <MenuItem key={category.value} value={category.value}>
                      {category.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Row>
        <Form.Row id="contents">
          <Form.Control as="textarea" onChange={handleChangeContent} maxLength={200} value={contents} rows={20} />
        </Form.Row>
        <div className="btnArea">
          <Button
            id="registBtn"
            type="button"
            onClick={handleSaveBtn}
            disabled={!boardTitle || !contents || !selectedCategory}
          >
            <p>등록</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={() => setShowModal(true)}>
            <p>취소</p>
          </Button>
        </div>
      </Form>
      <ConfirmModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'게시글 작성을 취소하시겠습니까?'}
        message={'게시글 작성을 취소하시면 작업중인 내용이 모두 초기화됩니다.'}
        confirmLabel={'예, 취소합니다.'}
        cancelLabel={'아니요'}
        onConfirm={handleCancelBtn}
      />
    </div>
  );
};

export default BoardEditForm;
