// 본인의 번호를 닉네임으로 변경하세요.
export const nickname = [
  'campUser01',
  'campUser02',
  'campUser03',
  'campUser04',
  'campUser05',
  'campUser06',
  'campUser07',
  'campUser08',
  'campUser09',
  'campUser10',
  'campUser11',
  'campUser12',
  'campUser13',
  'campUser14',
  'campUser15',
  'campUser16',
  'campUser17',
];
