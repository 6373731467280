import { nickname } from '../../nickname';
import BaseService from '../BaseService';
import CommonResponse from '../common/model/CommonResponse';
import { Service } from '../common/model/Service';
import { deepTrim } from '../common/utils/util';
import { BoardSearchCondition, RegistBoardRequest, UpdateBoardRequest } from './model/Board';

const idx = 15;

/* eslint-disable */
let mockResponse: CommonResponse = {
  successOrNot: 'Y',
  statusCode: 'SUCCESS',
  data: {},
};
export default class BoardService extends BaseService {
  async getBoardList(condition: BoardSearchCondition): Promise<CommonResponse> {
    const method = 'GET';
    const url = `/v1/boards-${nickname[idx]}`;
    const serviceName = Service.BOARD;
    const params = Object.assign({}, deepTrim(condition));
    const body = null;
    let response: CommonResponse;

    if (params.category && params.category.length > 0) {
      params.category = JSON.stringify(params.category);
    }

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error: any) {
      response = error;
    }
    return response;
  }

  async getBoardDetail(boardId: number): Promise<CommonResponse> {
    const method = 'GET';
    const url = `/v1/board-${nickname[idx]}/` + boardId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName, true);
    } catch (error: any) {
      response = error;
    }
    return response;
  }

  /* eslint-disable */
  async createBoardData(requestData: RegistBoardRequest): Promise<CommonResponse> {
    const method = 'POST';
    const url = `/v1/board-${nickname[idx]}`;
    const serviceName = Service.BOARD;
    const params = null;
    const body = JSON.stringify(requestData);
    let response: CommonResponse;

    try {
      response = mockResponse;
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error: any) {
      response = error;
    }
    return response;
  }

  async updateBoardData(requestData: UpdateBoardRequest): Promise<CommonResponse> {
    const method = 'PUT';
    const url = `/v1/board-${nickname[idx]}/` + requestData.boardId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = JSON.stringify(requestData);
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error: any) {
      response = error;
    }
    return response;
  }

  async deleteBoardData(boardId: number): Promise<CommonResponse> {
    const method = 'DELETE';
    const url = `/v1/board-${nickname[idx]}/` + boardId;
    const serviceName = Service.BOARD;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      //response = mockResponse;
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error: any) {
      response = error;
    }
    return response;
  }
}
