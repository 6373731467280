import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board4/BoardService';
import { RegistBoardRequest } from '../../../service/board4/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [contents, setContents] = useState('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);

  const history = useHistory();
  const boardService = new BoardService();

  // 컴포넌트에 서비스 객체를 만들고 컴포넌트가 만들어질때 데이터를 가져오게끔 처리합니다.
  const commonService = new CommonCodeService();

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(event.target.value);
  };

  const handleCategoryOnChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleSubmit = () => {
    console.log(`Title: ${boardTitle}`);
    console.log(`Category: ${selectedCategory}`);
    console.log(`Contents: ${contents}`);

    history.push('/board-4');
  };

  const handleCancelBtnOnClick = () => {
    history.push('/board-4');
  };

  const handleSaveBtnOnClick = () => {
    const requestData: RegistBoardRequest = {
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };
    void boardService.createBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-4');
      }
    });
  };

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        // return 된 값을 setCategoryList에 할당해줍니다.
        setCategoryList(res);
        setSelectedCategory(res[0].codeId);
      }
    });
  }, []); // eslint-disable-line

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도 한 마디
        </span>
        <span className="subTitle">등록하기</span>
      </div>
      <Form className="inputForm">
        <Form.Row className="category-row">
          <Row>
            <Col className="title-col">
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="제목을 입력하세요."
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={handleTitleOnChange}
                value={boardTitle}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select className="category" defaultValue="" value={selectedCategory} onChange={handleCategoryOnChange}>
                {categoryList.map((category) => {
                  return (
                    <MenuItem key={category.codeId} value={category.codeId}>
                      {category.codeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Row>
        <Form.Row id="contents">
          <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
        </Form.Row>
        <div className="btnArea">
          <Button
            id="registBtn"
            type="button"
            onClick={handleSaveBtnOnClick}
            disabled={!boardTitle || !contents || !selectedCategory}
          >
            <p>등록</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={handleCancelBtnOnClick}>
            <p>취소</p>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BoardRegistForm;
